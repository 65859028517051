import React from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import InputBase from "@material-ui/core/InputBase";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { withStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import SearchIcon from "@material-ui/icons/Search";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { AnimatedSwitch } from "react-router-transition";

import Theme from "./Theme";
import Chapter from "./Elements/ChapterContainer";
import TableOfContents from "./TableOfContents";
import Drawer from "./drawer";
import chapters from "./Chapters";

const styles = theme => ({
  root: {
    width: "100%",
    backgroundColor: "#F5F5F5",
    minHeight: "100vh",
    display: "flex"
  },
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
    paddingLeft: 0
  },
  drawerIcon: {
    [theme.breakpoints.up("sm")]: {
      display: "none"
    },
    paddingLeft: 0
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block"
    }
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing.unit,
      width: "auto"
    }
  },
  searchIcon: {
    width: theme.spacing.unit * 9,
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputRoot: {
    color: "inherit",
    width: "100%"
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 10,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: 120,
      "&:focus": {
        width: 200
      }
    }
  },
  appbar: {
    [theme.breakpoints.up("sm")]: {
      paddingLeft: 240
    }
  },
  content: {
    width: "100%",
    marginTop: 56,
    [`${theme.breakpoints.up("xs")} and (orientation: landscape)`]: {
      marginTop: 48
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: 64
    },
    overflow: "hidden"
  }
});

class App extends React.Component {
  state = {
    chapter: undefined,
    mobileOpen: false
  };

  setChapter = (chapter, number) => {
    this.setState({ chapter, number });
  };

  openMobile = () => {
    this.setState({ mobileOpen: true });
  };

  closeDrawer = () => {
    this.setState({ mobileOpen: false });
  };

  home = class Home extends React.Component {
    componentDidMount() {
      document.getElementsByClassName("switch-wrapper")[0].scrollTo(0, 0);
    }
    render() {
      return <TableOfContents />;
    }
  };

  chapters = ({ match }) => {
    return (
      <>
        <Route path={`${match.path}/:id`} component={this.chapter} />
        <Route
          exact
          path={match.path}
          render={() => <h3>Please select a topic.</h3>}
        />
      </>
    );
  };

  chapter = ({ match }) => {
    const chapter = chapters[match.params.id];
    if (!chapter) {
      return <h3>Kapitel nicht vorhanden</h3>;
    }
    return <Chapter data={chapter} />;
  };

  componentDidMount() {
    if (navigator.splashscreen) {
      navigator.splashscreen.hide();
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <Theme>
        <Router>
          <div className={classes.root}>
            <AppBar position="fixed" className={classes.appbar}>
              <Toolbar>
                {this.state.chapter ? (
                  <IconButton
                    className={classes.menuButton}
                    color="inherit"
                    onClick={() => this.setChapter(undefined, undefined)}
                  >
                    <ArrowBackIcon />
                  </IconButton>
                ) : (
                  <IconButton
                    className={classes.drawerIcon}
                    color="inherit"
                    onClick={this.openMobile}
                  >
                    <MenuIcon />
                  </IconButton>
                )}

                <Typography
                  className={classes.title}
                  variant="h6"
                  color="inherit"
                  noWrap
                >
                  Rohrhandbuch
                </Typography>
                <div className={classes.grow} />
                <div className={classes.search}>
                  <div className={classes.searchIcon}>
                    <SearchIcon />
                  </div>
                  <InputBase
                    placeholder="Suche"
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput
                    }}
                  />
                </div>
              </Toolbar>
            </AppBar>
            <Drawer
              mobileOpen={this.state.mobileOpen}
              onClose={this.closeDrawer}
            />
            <div className={classes.content}>
              <AnimatedSwitch
                atEnter={{ opacity: 0 }}
                atLeave={{ opacity: 0 }}
                atActive={{ opacity: 1 }}
                className="switch-wrapper"
                ref={this.scrollContainer}
              >
                <Route path="/chapter" component={this.chapters} />
                <Route component={this.home} />
              </AnimatedSwitch>
            </div>
          </div>
        </Router>
      </Theme>
    );
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(App);
