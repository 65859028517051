import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paragraph from './Paragraph'
import Typography from '@material-ui/core/Typography';
import { transform } from '../helper';

const styles = theme => ({
    caption: {
        color: '#555555',
        marginTop: theme.spacing.unit * 6
    }
});

function Section({ section, classes, id }) {
    return <React.Fragment>
        <Typography variant="h5" gutterBottom className={classes.caption} id={id}>
            <span dangerouslySetInnerHTML={transform(section.caption)}></span>
        </Typography>
        {section.paragraphs.map((paragraph, i) => <Paragraph paragraph={paragraph} key={i} />)}
    </React.Fragment>
}

export default withStyles(styles)(Section);