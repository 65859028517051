import React from 'react';
import Typography from '@material-ui/core/Typography';
import { transform } from '../helper';

export default function Footnotes({ footnotes }) {
    return <Typography variant="caption" gutterBottom>
        {footnotes.map((note, i) => <div key={i}>
            <sup>{i + 1})</sup>
            <span dangerouslySetInnerHTML={transform(note)} />
        </div>)}
    </Typography>
}