import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withRouter } from "react-router-dom";


const styles = theme => ({
  card: {
    maxWidth: 345,
    margin: theme.spacing.unit * 2
  },
  media: {
    height: 140,
  },
  flex: {
    flex: 1
  }
});

function ChapterCard(props) {
  const { classes, chapter, route, history } = props;
  const { card, caption } = chapter;
  const onClick = () => history.push(`/chapter/${route}`);
  return (
    <Card className={classes.card} onClick={onClick}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image={card.image}
          title={caption}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {caption}
          </Typography>
          <Typography component="p" align="justify">
            {card.text}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <div className={classes.flex}></div>
        <Button size="small" color="secondary" variant="outlined">
          Weitere Informationen
          </Button>
      </CardActions>
    </Card>
  );
}

ChapterCard.propTypes = {
  classes: PropTypes.object.isRequired,
  chapter: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(ChapterCard));