import React from "react";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#878787",
      contrastText: "#FFFFFF"
    },
    secondary: {
      main: "#00b0f0",
      contrastText: "#FFFFFF"
    }
  }
});

export default function ThemedApp(props) {
  return <MuiThemeProvider theme={theme}>{props.children}</MuiThemeProvider>;
}
