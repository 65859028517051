import React from 'react';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { withRouter, matchPath } from "react-router-dom";
import chapters from './Chapters';
import { transform } from './helper';
import { ReactComponent as Logo } from './logo.svg';


const styles = theme => ({
  toolbar: theme.mixins.toolbar,
  nested: {
    paddingLeft: theme.spacing.unit * 4,
  },
});



class DrawerContent extends React.Component {

  goHome = () => {
    this.props.history.push("/");
    this.props.onClose();
  }

  goChapter = (chapter, close = true) => () => {
    this.props.history.push(`/chapter/${chapter}`);
    close && this.props.onClose();
  }

  goSection = (section) => () => {
    this.props.history.replace(`#${section}`);
    this.props.onClose();
  }

  renderSections = (sections, open, classes) =>
    <Collapse in={open} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        {sections.map((section, index) => {
          const selected = this.props.location.hash.split('#')[1] * 1 === index;
          return (
            <ListItem button selected={selected} className={classes.nested} key={index} onClick={this.goSection(index)}>
              <ListItemText primary={<span dangerouslySetInnerHTML={transform(section.caption)}></span>} />
            </ListItem>)
        }
        )}
      </List>
    </Collapse>;


  render() {
    const { classes, location } = this.props;
    const path = location && matchPath(location.pathname, { path: "/chapter/:chapter" });
    return (
      <div>
        <ListItem button className={classes.toolbar} onClick={this.goHome}>
          <Logo style={{ height: '100%', width: '100%' }} />
        </ListItem>
        <Divider />
        <List>
          {Object.keys(chapters).map((key, index) => {
            const chapter = chapters[key];
            const selected = path && path.params.chapter === key;
            return <div key={key}>
              <ListItem selected={selected} button onClick={this.goChapter(key, selected)}>
                <ListItemText primary={chapter.caption} />
                {selected ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              {this.renderSections(chapter.sections, selected, classes)}
            </div>;

          })}
        </List>
      </div>
    );
  }
}

DrawerContent.propTypes = {
  classes: PropTypes.object.isRequired,
};
DrawerContent = withRouter(DrawerContent);
export default withStyles(styles, { withTheme: true })(DrawerContent);