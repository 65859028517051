import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Footnotes from "./Footnotes";
import { transform } from "../helper";

const styles = theme => ({
  root: {
    marginTop: theme.spacing.unit * 1,
    marginBottom: theme.spacing.unit * 1
  },
  paper: {
    width: "100%",
    overflowX: "auto"
  },
  table: {
    minWidth: "100%"
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default
    }
  }
});

const CustomTableCell = withStyles(theme => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    borderBottom: "none"
  },
  head: {
    fontSize: 14
  }
}))(TableCell);

function renderHeadCell(cell, colSpan, i) {
  return (
    <CustomTableCell
      key={i}
      align={["left", "right"][i === 0]}
      colSpan={colSpan}
    >
      <span dangerouslySetInnerHTML={transform(cell)} />
    </CustomTableCell>
  );
}

function renderCell(cell, colSpan, i) {
  return (
    <TableCell key={i} align={["left", "right"][i === 0]} colSpan={colSpan}>
      <span dangerouslySetInnerHTML={transform(cell)} />
    </TableCell>
  );
}

function renderRow(renderCell, row) {
  return row.reduce(
    (acc, cell, i) => {
      if (cell === null) {
        acc.span++;
        return acc;
      }
      acc.x.push(renderCell(cell, acc.span, i));
      acc.span = 1;
      return acc;
    },
    { x: [], span: 1 }
  ).x;
}

function MyTable({ classes, table }) {
  const { data, nHeads, footnotes, minWidth } = table;
  const rows = [...data];
  const headRows = rows.splice(0, nHeads || 1);
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Table className={classes.table} padding="dense" style={{ minWidth }}>
          <TableHead>
            {headRows.map((row, headI) => (
              <TableRow key={headI}>{renderRow(renderHeadCell, row)}</TableRow>
            ))}
          </TableHead>
          <TableBody>
            {rows.map((row, i) => (
              <TableRow className={classes.row} key={i}>
                {renderRow(renderCell, row)}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      {footnotes && <Footnotes footnotes={footnotes} />}
    </div>
  );
}

export default withStyles(styles)(MyTable);
