import React from "react";
import Section from "./Section";
import Typography from "@material-ui/core/Typography";
import scrollIntoView from "scroll-into-view-if-needed";
import { withRouter } from "react-router-dom";

class Chapter extends React.Component {
  scrollToSection = () => {
    if (!this.props.location.hash) return;
    const node = document.getElementById(
      this.props.location.hash.split("#")[1]
    );
    scrollIntoView(node, {
      behavior: "auto",
      block: "start"
    });
  };
  componentDidUpdate() {
    this.scrollToSection();
  }
  componentDidMount() {
    this.scrollToSection();
  }

  render() {
    const { data } = this.props;
    return (
      <React.Fragment>
        <Typography variant="h4" gutterBottom>
          {data.caption}
        </Typography>
        {data.sections.map((section, i) => (
          <Section section={section} key={i} id={i} />
        ))}
      </React.Fragment>
    );
  }
}

export default withRouter(Chapter);
