import Image1 from './images/image1.png';
import Image2 from './images/image2.png';
import Image3 from './images/image3.png';
import Image4 from './images/image4.png';
import Image5 from './images/image5.png';
import waterImage from './images/water.jpg';

export default {
    caption: "Stahlleitungsrohre für Wasser",
    card: {
        image: waterImage,
        text: `Die DIN 2460, Ausgabe 06/2006, legt die Ausführungen von
        Stahlrohren und -formstücken zum Bau und dem Betrieb von
        Wasser- und Abwasserleitungen fest. Bei der Auslegung der
        Rohre werden sowohl statische wie auch betriebsbedingte
        Bedingungen berücksichtigt.`
    },
    sections: [
        {
            caption: "Technische Lieferbedingungen",
            paragraphs: [
                {
                    type: "paragraph",
                    data: "Die DIN 2460, Aus~ga~be 06/2006, legt die Ausführungen von Stahl~roh~ren und -formstücken zum Bau und dem Be~trieb von Was~ser- und Ab~was~ser~lei~tun~gen fest. Bei der Aus~le~gung der Roh~re wer~den so~wohl sta~ti~sche wie auch be~triebs~be~ding~te Be~din~gun~gen berücksichtigt.",
                },
                {
                    type: "paragraph",
                    data: "Mit der Einführung neuer Technischer Lieferbedingungen für Stahlrohre im Rahmen der europäischen Harmonisierung und auch neuer Normen für Auskleidungen und Beschichtungen wurde eine Überarbeitung dieser Norm notwendig. Die vorliegende Ausgabe der Norm beinhaltet nunmehr den aktuellen Stand der deutschen bzw. europäischen Normung zum Zeitpunkt ihres Erscheinens.",
                },
                {
                    type: "paragraph",
                    data: "Die Norm gilt in erster Linie für die Verwendung von Stahlrohren für Wasser- und Abwasserleitungen, kann aber auch für andere Anwendungen wie z.B. für Roh-, Brauch- und Kühlwasser sowie für Meerwasser, Salzwasser und Sole sinngemäß angewendet werden.",
                },
                {
                    type: "paragraph",
                    data: "Für Rohre werden Güten nach DIN EN 10224 (L235, L275 oder L355 entsprechend den früher verwendeten Stahlgüten St 37.0, St 44.0 und St 52.0) eingesetzt, für Formstücke die Stahlgüten nach DIN EN 10253-1 oder DIN 2609.",
                },
                {
                    type: "paragraph",
                    data: "In der DIN 2460 sind alle zur Bestellung von Stahlrohren und -formstücken erforderlichen Informationen enthalten, angefangen mit den erforderlichen Bestellangaben, den möglichen Verbindungstechniken sowie Auskleidungs- und Beschichtungsmöglichkeiten und den möglichen Prüfbescheinigungen.",
                },
                {
                    type: "paragraph",
                    data: "Im Taschenbuch werden beispielhaft für die angegebenen Nennwanddicken die zulässigen Betriebsdrücke PFA dargestellt. Im Falle der Erdverlegung ist hierbei die Beanspruchung aus der Erddeckung im Bereich von 0,6 m bis 6 m einschließlich einer Verkehrsbelastung SLW 60 nach DIN 1072 und ein möglicher Abfall des Innendruckes auf den absoluten Druck <code>p<sub>abs</sub> = 0,2 bar</code> berücksichtigt. Alle Stahlleitungsrohre und -formstücke für die Trinkwasserversorgung müssen hinsichtlich ihrer Funktionsfähigkeit und hygienischen Unbedenklichkeit zertifiziert sein und unterliegen einer Fremdüberwachung.",
                },
                {
                    type: "paragraph",
                    data: "Weitere Informationen entnehmen Sie bitte der DIN 2460, zur Klärung technischer oder kaufmännischer Fragen sprechen Sie uns bitte an.",
                },
            ]
        },
        {
            caption: "Produktübersicht",
            paragraphs: [
                {
                    type: "paragraph",
                    data: "MLP-Wasserleitungsrohre sind nach folgenden Normen bzw. Spezifikationen lieferbar:"
                },
                {
                    type: "table",
                    data: [
                        ["Lieferprogramm", "Werkstoffe"],
                        ["DIN 2460 (DIN 10224<sup>1)</sup>)", "L235 (früher St 37.0)<BR>L275 (früher St 44.0)<BR>L355 (früher St 52.0)"],
                        ["Kundenspezifikationen", "Andere Werkstoffe auf Anfrage"]
                    ],
                    footnotes: ["Technische Lieferbedingungen für das rohschwarze Rohr"]
                },
                {
                    type: "paragraph",
                    subtitle: true,
                    data: "Vergleichbare Stahlsorten:"
                },
                {
                    type: "table",
                    nHeads: 2,
                    data: [
                        [null, "DIN EN 10224", null, "DIN EN 10208-1", null, "DIN EN 10208-2", null, "Frühere Werkstoffe nach DIN 1626,<BR>DIN 1629 und<BR>DIN 2609"],
                        ["Stahl~sorte", "Werk~stoff~nummer", "Stahl~sorte", "Werk~stoff~nummer", "Stahl~sorte", "Werk~stoff~nummer", "Stahl~sorte", "Werk~stoff~nummer"],
                        ["L235", "1.0252", "L235 GA", "1.0458", "L245 NB/MB", "1.0457/1.0418", "(St 37.0)", "(1.0254)"],
                        ["L275", "1.0260", "L290 GA", "1.0483", "L290 NB/MB", "1.0484/1.0429", "(St 44.0)", "(1.0256)"],
                        ["L355", "1.0419", "L360 GA", "1.0499", "L360 NB/MB", "1.0552/1.0578", "(St 52.0)", "(1.0421)"],
                    ]
                },
                {
                    type: "paragraph",
                    subtitle: true,
                    data: "Lieferprogramm:"
                },
                {
                    type: "table",
                    minWidth: 500,
                    data: [
                        ["Abmessungen", "Umhüllungen/~Beschichtungen/~Auskleidungen"],
                        ["Durchmesser: 114,3 - 610,0&nbsp;mm <BR> Wanddicken: 3,2 - 25,4&nbsp;mm <BR> Rohrlängen: 6,0 - 16,0&nbsp;m",
                            `<ul>
                                    <li>Polyethylen (PE)-Umhüllung nach DIN 30670</li>
                                    <li>Polyethylen (PE)-Umhüllung mit Rippenprofil nach DIN 30670</li>
                                    <li>Polypropylen (PP)-Umhüllung nach DIN 30678</li>
                                    <li>Faserzementmörtel (FZM)-Ummantelung nach DVGW-Arbeitsblatt GW 340, Ausführung N oder S</li>
                                    <li>Zementmörtel (ZM)-Auskleidung nach DIN EN 10298 und DIN 2880 (Anwendung)</li>
                                    <li>Feuerverzinkung DIN EN 10240</li>
                                    <li>Kundenspezifikation</li>
                                </ul>`
                        ]
                    ]
                },
                {
                    type: "paragraph",
                    subtitle: true,
                    data: "Eingesetzte Werkstoffe nach DIN EN 10224:"
                },
                {
                    type: "table",
                    nHeads: 3,
                    data: [
                        ["Stahl~kurz~name", "Zug~festig~keit <code>R<sub>m</sub></code>", null, "Streck~grenze <code>R<sub>p0,2</sub></code>", null, "Bruch~dehnung A", "Durchmesser des Biege~stempels beim Biege~versuch", null, "Durch~mes~ser~ver~größ~erung beim Aufweitversuch"],
                        ["", "<code>MPa</code>", null, "<code>MPa<sub>min</sub></code> für Wanddicken T in mm",
                            null, "(<code>L<sub>a</sub> = 5,65&#8730;S<sub>0</sub></code>)<BR>%<BR>min.", "", null, "in % für ein Verhältnis <code>d/D</code>"],
                        ["", "", "T&#8804;16", "T&#62;16", "Längst", "Quer", "", "&#8804;0,8", "T&#62;0,8"],
                        ["L235", "360 bis 500", "235", "225", "25", "23", "3 T", "10", "12"],
                        ["L275", "430 bis 570", "275", "265", "21", "19", "4 T", "8", "10"],
                        ["L355", "500 bis 650", "355", "345", "21", "19", "4 T", "6", "8"],



                    ]
                },
                {
                    type: "paragraph",
                    subtitle: true,
                    data: "Endenausführung der ZM-Aus~klei~dung bei Roh~ren mit Stumpf~schw~eiß~ver~bind~ung:",
                },
                {
                    type: "paragraph",
                    data: "Die Endenausführung wird durch die DIN EN 10298 / DIN 2880 vorgegeben. Es ist zwischen zwei Ausführungen zu wählen, wobei sich die Ausführung Typ C3* aufgrund der leichteren Handhabbarkeit als Standard durchgesetzt hat:"
                },
                {
                    type: "image",
                    image: Image1,
                },
                {
                    type: "footnotes",
                    data: [
                        "nach Anhang A der DIN EN 10298"
                    ]
                },
                {
                    type: "paragraph",
                    data: "Bei Schnittrohren kann die Endenausführung Typ C3 mit einem Winkelschleiferaufsatz (siehe 4. Kapitel Zubehör) wieder neu vorbereitet werden."
                },
                {
                    type: "paragraph",
                    subtitle: true,
                    data: "Endenausführung der Umhüllung bzw. Ummantelung:",
                },
                {
                    type: "paragraph",
                    data: "Die Rohrenden sind in der Regel ca. 90&nbsp;mm frei von Epoxidharz und Kleber, die eigentliche Polyethylen-Umhüllung ist standardmäßig ca. 110 mm zurückgeschnitten. Bei Rohren mit zusätzlicher FZM-Ummantelung ist die Ummantelung  ca. 90 mm auf dem Polyethylen zurückgesetzt, so dass sich ein FZM-freies Rohrende von ca. 200 mm ergibt."
                },
            ]
        },
        {
            caption: "Leitungsrohre mit Stumpf~schweiß~ver~bindung für Trink~wasser (ZSK-V)",
            paragraphs: [
                {
                    type: "image",
                    image: Image2,
                },
                {
                    type: "table",
                    nHeads: 4,
                    data: [
                        ["Nenn~weite", "Rohr~außen~durch~messer", "Nenn~wand~dicke", "Min~dest~schicht~dicke", "Längen~be~zogene Masse", null, "Zu~läs~sig~er Bau~teil~be~triebs~druck der Rohr~leit~ung"],
                        ["", "", "s", "ZM-~Aus~klei~dung", "(inkl. Korro~sions~schutz)", null, "PFA<sup>2)</sup><BR>Ab~nahme~prüf~zeug~nis 3.1"],
                        ["", "", "", "", "", "L235 mit v<sub>N</sub>=1,0", "L355 mit v<sub>N</sub>=1,0"],
                        ["DN", "mm", "mm<sup>1)</sup>", "mm<sup>3)</sup>", "kg/m<sup>1)</sup>", "bar<sup>1)</sup>", "bar<sup>1)</sup>"],
                        ["100", "114,3", "3,6/3,2", "3/8", "13,6/12,6", "91/81", "131/116"],
                        ["125", "139,7", "4,0/3,6", "3/8", "18,2/16,9", "83/74", "119/107"],
                        ["150", "168,3", "4,0/3,6", "3/8", "22,1/20,5", "68/62", "98/89"],
                        ["200", "219,1", "4,5/3,6", "3/8", "31,5/26,9", "59/47", "85/68"],
                        ["250", "273,0", "5,0/4,0", "3/8", "42,7/36,3", "53/42", "76/60"],
                        ["300", "323,9", "5,6/4,5", "4/8", "58,8/50,4", "50/39", "71/57"],
                        ["350", "355,6", "5,6/4,5", "4/8", "64,7/55,4", "45/36", "65/52"],
                        ["400", "406,4", "6,3/5,0", "4/8", "80,9/74,2", "44/35", "64/51"],
                        ["500", "508,0", "6,3/5,6", "4/8", "102,0/93,5", "35/31", "51/45"],
                        ["600", "610,0", "6,3/7,1", "4/8", "122,9/134,6", "29/33", "42/48"],
                    ],
                    footnotes: [
                        "Wanddicken nach DIN 2460 (weitere Wanddicken auf Anfrage)",
                        "berechnet nach DIN 2413, andere Betriebsdrücke auf Anfrage",
                        "Typ C2"
                    ]
                }
            ]
        },
        {
            caption: "Leit~ungs~rohre mit Ein~steck~schweiß~muf~fen-Ver~bin~dung für Ab~wasser (ZSK-MA)",
            paragraphs: [
                {
                    type: "image",
                    image: Image3,
                    caption: "Verbindung mit Gummianschlagring in der Muffe und zusätzlicher Nachauskleidung auf Polyurethanbasis."
                },
                {
                    type: "table",
                    nHeads: 4,
                    data: [
                        ["Nenn~weite", "Rohr~außen~durch~messer", "Nenn~wand~dicke", "Min~dest~schicht~dicke", "Längen~be~zogene Masse", "Einstecktiefe", "Muffenspiel", null, "Zu~läs~sig~er Bau~teil~be~triebs~druck der Rohr~leit~ung"],
                        ["", "", "s", "ZM-~Aus~klei~dung", "(inkl. Korro~sions~schutz)", "t", "f", null, "PFA<sup>2)</sup><BR>Ab~nahme~prüf~zeug~nis 3.1"],
                        ["", "", "", "", "", "", "", "L235 mit v<sub>N</sub>=1,0", "L355 mit v<sub>N</sub>=1,0"],
                        ["DN", "mm", "mm<sup>1)</sup>", "mm<sup>3)</sup>", "kg/m<sup>1)</sup>", "mm", "mm", "bar<sup>1)</sup>", "bar<sup>1)</sup>"],
                        ["100", "114,3", "3,6/3,2", "6", "17,2/16,2", "55", "1,5", "91/81", "131/116"],
                        ["125", "139,7", "4,0/3,6", "6", "22,6/21,4", "60", "1,5", "83/74", "119/107"],
                        ["150", "168,3", "4,0/3,6", "6", "27,6/26,1", "65", "1,5", "68/62", "98/89"],
                        ["200", "219,1", "4,5/3,6", "6", "39,0/34,4", "80", "2", "59/47", "85/68"],
                        ["250", "273,0", "5,0/4,0", "6", "52,2/45,8", "90", "2", "53/42", "76/60"],
                        ["300", "323,9", "5,6/4,5", "6", "67,0/58,6", "105", "2", "50/39", "71/57"],
                        ["350", "355,6", "5,6/4,5", "6", "73,8/64,6", "115", "2,5", "45/36", "65/52"],
                        ["400", "406,4", "6,3/5,0", "6", "91,4/84,7", "120", "2,5", "44/35", "64/510"],
                        ["500", "508,0", "6,3/5,6", "6", "114,8/106,4", "130", "3", "35/31", "51/45"],
                        ["600", "610,0", "6,3/7,1", "6", "139,0/150,6", "130", "3", "29/33", "42/48"],
                    ],
                    footnotes: [
                        "Wanddicken nach DIN 2460 (weitere Wanddicken auf Anfrage)",
                        "berechnet nach DIN 2413, andere Betriebsdrücke auf Anfrage",
                    ]
                }
            ]
        },
        {
            caption: "Rohre mit Nut-Ver~bind~ung für Trink~was~ser (ZSK-N)",
            paragraphs: [
                {
                    type: "image",
                    image: Image4,
                },
                {
                    type: "table",
                    nHeads: 4,
                    minWidth: 1100,
                    data: [
                        ["Nenn~weite", "Durch~mes~ser", "Rillen~durch~mes~ser", null, "Rillen~brei~te", "Nenn~wand~dicke", "Min~dest~schicht~dicke", "Längen~be~zogene Masse", null, "Zu~läs~sig~er Bau~teil~be~triebs~druck der Rohr~leit~ung"],
                        ["", "", "", null, "", "", "ZM-~Aus~klei~dung", "(inkl. Korro~sions~schutz)", null, "PFA<sup>2)</sup><BR>L235 mit v<sub>N</sub>=1,0 und Ab~nahme~prüf~zeug~nis 3.1"],
                        ["", "", "", null, "", "", "", "", "ge~roll~te Nut", "ge~dreh~te Nut"],
                        ["DN", "mm", "mm", "mm", "mm", "mm", "mm", "kg/m", "bar", "bar"],
                        ["100", "114,3", "110,1", "9,5", "8,7", "3,6", "3", "13,6", "40", "69"],
                        ["125", "139,7", "135,5", "9,5", "8,7", "4,0", "3", "18,2", "34", "69"],
                        ["150", "168,3", "164,0", "9,5", "8,7", "4,0", "3", "22,1", "34", "69"],
                        ["200", "219,1", "214,4", "11,1", "11,9", "4,5", "3", "31,5", "34", "55"],
                        ["250", "273,0", "268,3", "12,7", "11,9", "5,0 (6,35)", "3", "42,7 (51,5)", "34", "55"],
                        ["300", "323,9", "318,3", "12,7", "11,9", "5,6 (7,1)", "4", "58,9 (70,4)", "34", "55"],
                        ["350", "355,6", "350,0", "12,7", "11,9", "5,6", "4", "64,7", "21", "21"],
                        ["400", "406,4", "400,8", "12,7", "11,9", "6,3", "4", "80,9", "21", "21"],
                        ["500", null, null, null, null, null, null, null, null, "Auf Anfrage"],
                        ["600", null, null, null, null, null, null, null, null, "Auf Anfrage"],
                    ],
                    footnotes: [
                        "weitere Wanddicken auf Anfrage",
                    ]
                }
            ]
        },
        {
            caption: "Rohre mit Fla~nsch-~Ver~bin~dung für Trink- oder Ab~wasser (ZSK-FL oder ZSK-FLA)",
            paragraphs: [
                {
                    type: "image",
                    image: Image5,
                },
                {
                    type: "table",
                    nHeads: 3,
                    minWidth: 800,
                    data: [
                        ["Nenn~weite", "Rohr~außen~durch~messer", "Nenn~wand~dicke", "Min~dest~schicht~dicke", "Längen~be~zogene Masse", null, "Zu~läs~sig~er Bau~teil~be~triebs~druck der Rohr~leit~ung"],
                        ["", "", "s", "ZM-~Aus~klei~dung", "", "PFA"],
                        ["DN", "mm", "mm", "mm<sup>1)</sup>", "kg/m", "bar"],
                        ["100", "114,3", "3,2", "3/6", "8,8", "16"],
                        ["150", "168,3", "3,6", "3/6", "14,6", "16"],
                        ["200", "219,1", "3,6", "3/6", "19,1", "16"],
                        ["250", "273,0", "4,0", "3/6", "26,5", "16"],
                        ["300", "323,9", "4,5", "4/6", "35,4", "16"],
                        ["350", "355,6", "4,5", "4/6", "39,0", "16"],
                        ["400", "406,4", "5,0", "4/6", "49,5", "16"],
                        ["500", "508,0", "5,6", "4/6", "69,4", "16"],
                        ["600", "610,0", "7,1", "4/6", "93,8", "16"],

                    ],
                    footnotes: [
                        "für Abwasser oder andere aggressive Wässer",
                        "weitere Wanddicken auf Anfrage"
                    ]
                }
            ]
        },
    ]
}