import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import ChapterCard from './Elements/ChapterCard';
import Teaser from './Teaser';

import chapters from './Chapters';

const styles = theme => ({
    toc: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexWrap: 'wrap',
        flexDirection: 'row',
        width: '100%'
    },
});

function TableOfContents({ classes }) {
    return <div className={classes.toc}>
        <Teaser />
        {Object.keys(chapters).map((key) =>
            <ChapterCard chapter={chapters[key]} route={key} key={key} />
        )}
    </div>
}

export default withStyles(styles)(TableOfContents);

