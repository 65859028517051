import React from 'react';
import Typography from '@material-ui/core/Typography';
import Table from './Table';
import Footnotes from './Footnotes';
import Image from './Image';
import { transform } from '../helper';

export default function Paragraph({ paragraph }) {
    if (paragraph.type === "paragraph") {
        return <Typography variant={["body1", "h6"][paragraph.subtitle + 0]} gutterBottom className="hyphens">
            <span dangerouslySetInnerHTML={transform(paragraph.data)}></span>
        </Typography>;
    }
    if (paragraph.type === "table") {
        return <Table table={paragraph} />;
    }
    if (paragraph.type === "footnotes") {
        return <Footnotes footnotes={paragraph.data} />;
    }
    if (paragraph.type === "image") {
        return <Image image={paragraph.image} caption={paragraph.caption} />;
    }
    return paragraph.type;
}