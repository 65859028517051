import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import GenericChapter from './Chapter';

const styles = theme => ({
  root: {
    padding: theme.spacing.unit * 2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  container: {
    maxWidth: 500,
    width: '100%'
  }
});

function Chapter(props) {
  const { classes, data, number } = props;
  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <GenericChapter data={data} number={number} />
      </div>
    </div>
  );
}

Chapter.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Chapter);