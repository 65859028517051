import React from "react";
import ImageZoom from "react-medium-image-zoom";
import Typography from "@material-ui/core/Typography";
import { withRouter } from "react-router-dom";

class Footnotes extends React.Component {
  state = {
    zoom: false
  };

  static getDerivedStateFromProps(props) {
    return {
      zoom: (props.location.state || {}).image === props.image
    };
  }

  zoom = () => {
    !this.state.zoom &&
      this.props.history.push(this.props.location.pathname, {
        image: this.props.image
      });
  };

  unzoom = () => {
    this.state.zoom && this.props.history.goBack();
  };

  render() {
    const { image, caption } = this.props;
    return (
      <React.Fragment>
        <ImageZoom
          image={{
            src: image,
            style: { width: "100%" }
          }}
          defaultStyles={{
            zoomContainer: {
              zIndex: 1200
            }
          }}
          isZoomed={this.state.zoom}
          onZoom={this.zoom}
          onUnzoom={this.unzoom}
        />
        <Typography variant="caption" gutterBottom>
          {caption}
        </Typography>
      </React.Fragment>
    );
  }
}

export default withRouter(Footnotes);
