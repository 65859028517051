import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

const bootstrap = () => ReactDOM.render(<App />, document.getElementById('root'));

if(window.hasOwnProperty('cordova')) {
    document.addEventListener("deviceready", bootstrap, false);
} else {
    bootstrap()
}
