import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import image from './pipes.jpg';
import logo from '../logo.svg';

const styles = theme => ({
    root: {
        width: '100%',
        backgroundImage: `url(${image})`,
        backgroundSize: 'cover',
        height: '30vh',
        position: 'relative'
    },
    text: {
        backgroundColor: 'rgba(255,255,255,0.8)',
        color: 'black',
        width: '100%',
        textAlign: 'center',
        bottom: 0,
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
        position: 'absolute',
        fontSize: 24,
        verticalAlign: 'middle',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        flexWrap: 'wrap'
    },
    logo: {
        height: 50,
        marginLeft: theme.spacing.unit
    }

});

function Teaser({ classes }) {
    return <div className={classes.root}>
        <div className={classes.text}>
            <img className={classes.logo} src={logo} alt="logo"></img>
        </div>
    </div>
}

export default withStyles(styles)(Teaser);

